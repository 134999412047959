<template>
  <div>
    <!--  중복 처리 이슈-->
    <QnaReply v-if="noteVisible" :class="{ show: noteVisible }" @close="closeModal2" :qnaData="qnaData" />
    <div class="Mypage">
      <div class="subHeader base">
        <h2>{{ $t("mypage_myinfo") }}</h2>
        <div class="grid c2">
          <article class="left subDash card">
            <div class="profile">
              <div class="info">
                <em>{{ username }}</em>
                <em>{{ type == "2" ? "파트너" : "" }}</em>
                <em class="point"
                  ><span>{{ numberFormat(point) }}</span> P</em
                >
              </div>
            </div>
            <ul class="menuList">
              <li @click="handleMenu('1')">
                <span class="material-icons">person</span>
                <b :class="{ primary: menuIdx == '1' }">{{ $t("mypage_profile") }}</b>
              </li>
              <li @click="handleMenu('4')">
                <span class="material-icons">loyalty</span>
                <b :class="{ primary: menuIdx == '4' }">{{ $t("mypage_membership") }}</b>
              </li>
              <li @click="handleMenu('6')">
                <span class="material-icons">settings</span>
                <b :class="{ primary: menuIdx == '6' }">{{ $t("mypage_changePwd") }}</b>
              </li>
              <li @click="handleMenu('3')">
                <span class="material-icons">credit_card</span>
                <b :class="{ primary: menuIdx == '3' }">{{ $t("mypage_charge") }}</b>
              </li>
              <li @click="handleMenu('2')">
                <span class="material-icons"> assignment </span><b :class="{ primary: menuIdx == '2' }">{{ $t("mypage_payment_management") }}</b>
              </li>
              <li @click="handleMenu('5')">
                <span class="material-icons">sms</span>
                <b :class="{ primary: menuIdx == '5' }">{{ $t("mypage_qna_management") }}</b>
              </li>
            </ul>
          </article>
          <article class="right subDash card">
            <div class="title">
              <h3 @click="handleDeleteBasket(0)">
                {{ menuTxt }}
              </h3>
            </div>
            <section>
              <div v-if="menuIdx == '1'" class="myInfo">
                <div class="mainBox noBorder">
                  <div class="form">
                    <fieldset>
                      <legend>정보입력</legend>
                      <div class="cover bg">
                        <label>{{ $t("email") }}</label>
                        <input type="text" v-model="userInfo.email" :placeholder="$t('email')" disabled />
                      </div>
                      <div class="cover bg">
                        <label>{{ $t("username") }}</label>
                        <input type="text" v-model="userInfo.username" :placeholder="$t('username')" />
                      </div>
                      <div class="cover bg">
                        <label>{{ $t("cellPhone") }}</label>
                        <div class="flex">
                          <input type="tel" v-model="mobileNo" :placeholder="$t('checkNum')" />
                          <button @click="requestCertNumber">
                            {{ $t("send") }}
                          </button>
                        </div>
                      </div>
                      <div class="cover bg" v-show="veyfyCodeVisible">
                        <div class="flex">
                          <input type="tel" v-model="certNumber" :placeholder="$t('inputCert')" />
                          <button @click="verifyNumber">
                            {{ $t("confirm") }}
                          </button>
                        </div>
                      </div>
                      <div class="cover bg">
                        <label>{{ $t("companyName") }}</label>
                        <input type="text" v-model="companyName" :placeholder="$t('inputCompany')" />
                      </div>
                    </fieldset>
                  </div>
                  <button class="save" @click="submit">저장하기</button>
                </div>
              </div>
              <!-- 나의  멤버십-->
              <div v-else-if="menuIdx == '4'" class="Branch">
                <el-tabs type="card">
                  <el-tab-pane label="충전 내역">
                    <div class="form join">
                      <div>
                        <form>
                          <fieldset>
                            <legend>나의 멤버십 현황</legend>
                            <table>
                              <tr>
                                <th>번호</th>
                                <th>날짜</th>
                                <th>충전수단</th>
                                <th>서비스 타입</th>
                                <th>충전 포인트</th>
                              </tr>
                              <tr v-for="(item, i) in pointChargeInfo" :key="i">
                                <td>{{ item.id }}</td>
                                <td>
                                  <span>{{ moment(item.createAt).format("YY.MM.DD HH:mm") }}</span>
                                </td>
                                <td>{{ item.checkout.card_name }}</td>
                                <td>
                                  {{ item.checkout.serviceType }}
                                </td>
                                <td>{{ item.point + item.plus_point }} P</td>
                              </tr>
                            </table>
                          </fieldset>
                        </form>
                        <div class="pagination">
                          <div class="pagination" v-if="total_pointCharge > 0">
                            <el-pagination layout="prev, pager, next" :total="total_pointCharge" :page-size="10" @current-change="handlePointChargeChange"></el-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="차감 내역">
                    <div class="form join">
                      <div>
                        <form>
                          <fieldset>
                            <legend>나의 멤버십 현황</legend>
                            <table>
                              <tr>
                                <th>번호</th>
                                <th>날짜</th>
                                <th>카테고리</th>
                                <th>차감 포인트</th>
                              </tr>
                              <tr v-for="(item, i) in pointDeductionInfo" :key="i">
                                <td>{{ item.id }}</td>
                                <td>
                                  <span>{{ moment(item.createAt).format("YY.MM.DD HH:mm") }}</span>
                                </td>
                                <td>
                                  {{ item.category == "refund" ? "환불" : "데이터 사용" }}
                                </td>
                                <td>{{ item.point }} P</td>
                              </tr>
                            </table>
                          </fieldset>
                        </form>
                        <div class="pagination">
                          <div class="pagination" v-if="total_pointDeduction > 0">
                            <el-pagination layout="prev, pager, next" :total="total_pointDeduction" :page-size="10" @current-change="handlePointDeductionChange"></el-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <!-- 결제 내역 -->
              <div v-else-if="menuIdx == '2'" class="Branch" id="membership">
                <el-tabs type="card">
                  <el-tab-pane label="결제 내역">
                    <div class="form join">
                      <div>
                        <form>
                          <fieldset>
                            <legend>나의 결제 관리</legend>
                            <table>
                              <tr>
                                <th>번호</th>
                                <th>결제 ID</th>
                                <th>날짜</th>
                                <th>지불 방법</th>
                                <th>충전 금액</th>
                                <th>충전 포인트</th>
                              </tr>
                              <tr v-for="(item, i) in chargeInfo" :key="i">
                                <td>{{ item.id }}</td>
                                <td>
                                  <router-link to @click.native="handleMenu('2-2', item._id)"
                                    >{{ item.merchant_uid }} <span>({{ item.card_name }})</span></router-link
                                  ><span>{{ moment(item.created_at).format("YY. MM. DD.") }}</span>
                                </td>
                                <td>
                                  {{ moment(item.created_at).format("YY. MM. DD.") }}
                                </td>
                                <td>{{ item.card_name }}</td>
                                <td>
                                  {{ numberFormat(item.paid_amount) }}원<span>{{ numberFormat(item.total_point) }} P</span>
                                </td>
                                <td>{{ numberFormat(item.total_point) }} P</td>
                              </tr>
                            </table>
                          </fieldset>
                        </form>
                        <div class="pagination" v-show="chargeInfo.length > 0">
                          <el-pagination layout="prev, pager, next" :total="total_charge" :page-size="10" @current-change="handleCurrentChange_charge"></el-pagination>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="취소 내역">
                    <div class="form join">
                      <div>
                        <form>
                          <fieldset>
                            <legend>나의 결제 관리</legend>
                            <table>
                              <tr>
                                <th>번호</th>
                                <th>결제 ID</th>
                                <th>날짜</th>
                                <th>환불 방법</th>
                                <th>차감 금액</th>
                                <th>차감 포인트</th>
                              </tr>
                              <tr v-for="(item, i) in deductionInfo" :key="i">
                                <td>{{ item.id }}</td>
                                <td>
                                  <router-link to @click.native="handleMenu('2-2', item._id)"
                                    >{{ item.merchant_uid }} <span>({{ item.card_name }})</span></router-link
                                  ><span>{{ moment(item.created_at).format("YY. MM. DD.") }}</span>
                                </td>
                                <td>
                                  {{ moment(item.created_at).format("YY. MM. DD.") }}
                                </td>
                                <td>{{ item.card_name }}</td>
                                <td>
                                  {{ numberFormat(item.refund_amount) }}원<span>{{ numberFormat(item.total_point) }} P</span>
                                </td>
                                <td>{{ numberFormat(item.total_point) }} P</td>
                              </tr>
                            </table>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div v-else-if="menuIdx == '2-2'" class="Branch" id="membership">
                <div class="payment">
                  <div class="form">
                    <fieldset>
                      <div>
                        <div class="flexB" v-if="checkoutData_status != 'cancelled'">
                          <p>결제일</p>
                          <span>
                            {{ moment(checkoutData_date).format("YYYY.MM.DD") }}
                          </span>
                        </div>
                        <div class="flexB" v-else>
                          <p>환불일</p>
                          <span>
                            {{ moment(checkoutData_updated_at).format("YYYY.MM.DD") }}
                          </span>
                        </div>
                        <div class="flexB">
                          <p>결제상품</p>
                          <span>{{ checkoutData_serviceType }}</span>
                        </div>
                        <div class="flexB" v-if="checkoutData_status != 'cancelled'">
                          <p>결제금액</p>
                          <span>{{ numberFormat(checkoutData_paid_amount) }} 원</span>
                        </div>
                        <div class="flexB" v-else>
                          <p>환불금액</p>
                          <span>{{ numberFormat(checkoutData_refund_amount) }} 원</span>
                        </div>
                        <!-- tab에 따라 이 부분 다르게 설정해주시면 될 것 같습니다! -->
                        <div class="flexB" v-if="checkoutData_status != 'cancelled'">
                          <p>충전 포인트</p>
                          <span>{{ numberFormat(checkoutData_total_point) }} P</span>
                        </div>
                        <div class="flexB" v-else>
                          <p>차감 포인트</p>
                          <span>{{ numberFormat(checkoutData_refund_point) }} P</span>
                        </div>
                        <div class="flexB">
                          <p>결제카드</p>
                          <span>{{ checkoutData_card_name }}</span>
                        </div>
                        <div class="flexB">
                          <p>카드번호</p>
                          <span>{{ checkoutData_card_num }}</span>
                        </div>
                        <!-- 결제완료 : success, 환불신청: primary, 환불완료 : orange, 환불불가 : error,  -->
                        <div class="flexB">
                          <p>상태</p>
                          <span
                            :class="{
                              success: checkoutData_status == 'paid',
                              error: checkoutData_status == 'paid_fail',
                              primary: checkoutData_status == 'refund',
                              orange: checkoutData_status == 'cancelled',
                              error: checkoutData_status == 'norefund',
                            }"
                          >
                            {{
                              checkoutData_status == "paid"
                                ? "결제 완료"
                                : checkoutData_status == "paid_fail"
                                ? "결제 실패"
                                : checkoutData_status == "refund"
                                ? "환불 신청"
                                : checkoutData_status == "cancelled"
                                ? "환불 완료"
                                : checkoutData_status == "norefund"
                                ? "환불 거절"
                                : ""
                            }}
                          </span>
                        </div>
                        <div class="buttonWrap">
                          <button class="apply" @click="handleMenu('2-3', item_id)" v-if="checkoutData_status == 'paid'">
                            환불신청
                          </button>
                          <button @click="handleMenu('2')">확인</button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div v-else-if="menuIdx == '2-3'" class="Branch" id="membership">
                <div class="refund payment">
                  <div class="form">
                    <fieldset>
                      <div>
                        <p>
                          환불 신청을 계속 하시려면 다음 설문에 답해주시기 바랍니다.
                        </p>
                        <p>
                          남겨주신 정보를 바탕으로 더 나은 서비스를 제공해드리기 위해 노력하겠습니다.
                        </p>
                        <div class="flexB">
                          <p>환불 사유</p>
                          <div>
                            <select class="select" v-model="refund_category" @change="changeRefund">
                              <option value="">환불사유를 선택해주세요.</option>
                              <option value="money">이용 요금이 비싸서</option>
                              <option value="usage">사용빈도가 낮아서</option>
                              <option value="etc">
                                기타(사유를 적어주시면 좋은 참고가 되겠습니다.)
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="flexB reason" v-show="refund_etc">
                          <p>기타 다른 사유</p>
                          <div>
                            <textarea name="" id="" cols="30" rows="10" v-model="refund_reason"></textarea>
                          </div>
                        </div>
                        <div class="buttonWrap">
                          <button class="apply" @click="handleRefund">
                            환불신청
                          </button>
                          <button @click="handleMenu('2-2', item_id)">
                            취소
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div v-else-if="menuIdx == '3'" class="myInfo charge">
                <div class="mainBox noBorder">
                  <div class="form">
                    <fieldset>
                      <legend>정보입력</legend>
                      <div class="chargeInput">
                        <p>충전 금액</p>
                        <div class="inputLine">
                          <div class="inputInner flexB">
                            <input type="number" placeholder="금액을 입력해주세요. (만원 단위)" :value="chargeAmont" @input="calculate" />
                            <button @click="amountCancel">
                              <span class="material-icons">cancel</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="chargeWrap flex">
                        <div class="chargeBox">
                          <button @click="calculPrice(10000)">+1만원</button>
                        </div>
                        <div class="chargeBox">
                          <button @click="calculPrice(50000)">+5만원</button>
                        </div>
                        <div class="chargeBox">
                          <button @click="calculPrice(100000)">+10만원</button>
                        </div>
                        <div class="chargeBox">
                          <button @click="calculPrice(1000000)">
                            +100만원
                          </button>
                        </div>
                      </div>
                      <div class="chargePoint">
                        <div class="flexB">
                          <p>충전 포인트</p>
                          <span v-if="!isNaN(chargeAmont) && chargeAmont > 99">{{ Math.floor(chargeAmont / 100) }}P</span>
                        </div>
                        <div class="flexB">
                          <p>추가 포인트</p>
                          <span v-if="chargeAmont >= 1000000">{{ numberFormat(plusPoint) }}P</span>
                        </div>
                        <div class="flexB">
                          <p>보유 포인트</p>
                          <span v-if="!isNaN(havingPoint) && havingPoint != 0">{{ numberFormat(havingPoint) }}P</span>
                        </div>
                        <div class="flexB">
                          <p>합계 포인트</p>
                          <span v-if="!isNaN(totalPoint) && totalPoint != 0">{{ numberFormat(totalPoint) }}P</span>
                        </div>
                      </div>
                      <div class="chargePayment">
                        <div class="flexB">
                          <p>최종결제 금액 (부가세 10% 포함)</p>
                          <span v-if="!isNaN(chargeAmont) && chargeAmont > 99">{{ comma(totalAmount) }} 원</span>
                        </div>
                      </div>
                      <div class="info">
                        <h3>충전 안내</h3>
                        <ul>
                          <li>- 1포인트 당 1개의 리뷰 분석이 가능합니다.</li>
                          <li>
                            - 최소 충전가능금액은 1만원이며 만원 단위로 충전이 가능합니다.
                          </li>
                          <li>
                            - 100만원 이상 결제 시 금액의 10%에 해당하는 포인트를 추가 지급합니다.(이후 50만원 단위로 10% 추가 포인트 지급)
                            <ul>
                              <li>ex1) 101만원 -> 10100포인트 + 1000포인트</li>
                              <li>ex2) 150만원 -> 15000포인트 + 1500포인트</li>
                              <li>ex3) 151만원 -> 15100포인트 + 1500포인트</li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </fieldset>
                  </div>
                  <button class="save" @click="handleCharge">충전하기</button>
                </div>
              </div>
              <div v-else-if="menuIdx == '5'" class="Branch" id="qna">
                <el-tabs type="card" v-model="activeName">
                  <el-tab-pane label="문의내역" name="first">
                    <div class="form join">
                      <div>
                        <form>
                          <fieldset>
                            <table>
                              <tr>
                                <th>번호</th>
                                <th>카테고리</th>
                                <th>제목</th>
                                <th>날짜</th>
                                <th>진행상태</th>
                              </tr>
                              <tr v-for="(data, i) in postList" :key="i">
                                <td>{{ i + 1 }}</td>
                                <td>
                                  {{ data.category == "service" ? "이용관련" : data.category == "payment" ? "결제관련" : data.category == "etc" ? "기타문의" : "" }}
                                  <span>
                                    <router-link to @click.native="handleQna(data._id)">{{ data.title }}</router-link>
                                  </span>
                                </td>
                                <td>
                                  <router-link to @click.native="handleQna(data._id)">{{ data.title }}</router-link>
                                </td>
                                <td>
                                  {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
                                  <span :class="[data.status ? 'orange' : 'primary']">
                                    {{ data.status ? "답변완료" : "답변대기" }}
                                  </span>
                                </td>
                                <td :class="[data.status ? 'orange' : 'primary']">
                                  {{ data.status ? "답변완료" : "답변대기" }}
                                </td>
                              </tr>
                            </table>
                          </fieldset>
                        </form>
                        <div class="pagination">
                          <el-pagination layout="prev, pager, next" :total="postTotal" :page-size="10" @current-change="handleCurrentChange_post"></el-pagination>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="문의하기" name="second">
                    <div class="form join">
                      <div>
                        <div class="cover bg">
                          <label>문의 유형</label>

                          <el-select v-model="category" placeholder="카테고리">
                            <el-option label="이용관련" value="service"> </el-option>
                            <el-option label="결제관련" value="payment"> </el-option>
                            <el-option label="기타문의" value="etc"> </el-option>
                          </el-select>
                        </div>
                        <div class="cover bg">
                          <label>문의 제목</label>
                          <input type="text" placeholder="최소 2자에서 최대 30자를 입력해주세요." v-model="postTitle" />
                        </div>
                        <div class="cover bg">
                          <label>문의 내용</label>
                          <textarea name id cols="30" rows="10" placeholder="문의 사항에 대해 최소 10자 이상을 입력해주세요." v-model="content"></textarea>
                        </div>
                        <div class="buttonWrap">
                          <button @click="postSubmit">작성하기</button>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div v-else-if="menuIdx == '6'" class="myInfo">
                <div class="mainBox noBorder">
                  <div class="form">
                    <fieldset>
                      <legend>정보입력</legend>
                      <div class="cover bg">
                        <!-- <label>{{ $t("username") }}</label> -->
                        <label>현재 비밀번호</label>
                        <input v-model="password" type="password" placeholder="현재 비밀번호 입력" />
                      </div>
                      <div class="cover bg">
                        <label>신규 비밀번호</label>
                        <input v-model="newPwd" type="password" placeholder="신규 비밀번호 입력" />
                      </div>
                      <div class="cover bg">
                        <label>신규 비밀번호 확인</label>
                        <input v-model="newPwdConfirm" type="password" placeholder="신규 비밀번호 확인" />
                      </div>
                    </fieldset>
                  </div>
                  <button class="save" @click="updatePwd">비밀번호 변경</button>
                </div>
              </div>
            </section>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { updateProfileInfo, fetchUserInfo, fetchUserMembership, changePassword } from "@/api/member";
import {
  deleteBasket,
  updateBasket,
  deletePaymentHistory,
  fetchUserPayments,
  fetchUserCharges,
  fetchUserdeductions,
  fetchPointList,
  fetchUserCheckoutDetail,
  refundState,
  refundRequest,
  cancelSubscribe,
  createCheckout,
  getCheckoutComplete,
  deleteCheckout,
  createCertNumber,
  verifyMobile,
} from "@/api/index";
import { createPost, fetchPostList, fetchPostDetail } from "@/api/post";
import sha256 from "js-sha256";
import { mapState } from "vuex";
import moment from "moment";
import { format } from "@/mixins/format";
import { baseUrl } from "@/config/env";
import { eventBus } from "@/main";
import { verifyPassword } from "@/mixins/verifyPassword";
import QnaReply from "@/components/note/QnaReply";

export default {
  mixins: [format, verifyPassword],
  components: { QnaReply },
  data() {
    return {
      displayBackground: false,
      optionDisplayCenterModal: false,
      refundDisplayCenterModal: false,
      displayBottomModal: false,
      type: "",
      title: "",
      noteVisible: false,
      moment: moment,
      notices: [],
      basket: [],
      file: null,
      baseUrl: baseUrl,
      menuTxt: this.$t("mypage_profile"),
      menuIdx: "1",
      mobileNo: "",
      username: "",
      point: 0,
      addressDetail: "",
      address: "",
      postcode: "",
      password: "",
      logo: "",
      currentPage: "1",
      totalPages: "",
      idx: "",
      buyInfo: [],
      reason: "",
      refundItem: "",
      companyName: "",
      merchant_uid: "",
      serviceType: "",
      subscribeTerm: "",
      nextPayment: "",
      activeName: "first",
      certNumber: "",
      // 충전관련
      chargeAmont: 0, // 충전금액
      totalAmount: 0, // 결제금액
      totalPoint: 0, // havingPoint + plusPoint 된 포인트
      havingPoint: 0, // 가지고 있는 포인트
      plusPoint: 0, // 추가 포인트
      // 문의사항
      category: "",
      content: "",
      postTitle: "",
      postList: [],
      postTotal: 0,
      postCurrentPage: 1,
      veyfyCodeVisible: false,
      verify: false,
      newPwd: "",
      newPwdConfirm: "",
      qnaData: [],
      chargeInfo: [],
      deductionInfo: [],
      pointChargeInfo: [],
      pointDeductionInfo: [],
      currentPage_charge: 1,
      currentPage_deduction: 1,
      currentPage_pointCharge: 1,
      currentPage_pointDeduction: 1,
      total_charge: 0,
      total_deduction: 0,
      total_pointCharge: 0,
      total_pointDeduction: 0,
      // checkout Detail
      item_id: "",
      checkoutData_status: "", // 상태
      checkoutData_date: "", // 결제일
      checkoutData_updated_at: "", // 환불일
      checkoutData_serviceType: "", // 결제상품
      checkoutData_paid_amount: 0, // 결제금액
      checkoutData_total_point: 0, //  충전포인트
      checkoutData_card_name: "", // 카드이름
      checkoutData_card_num: "", // 카드번호
      checkoutData_refund_amount: 0,
      checkoutData_refund_point: 0,
      // 환불신청
      refund_category: "",
      refund_reason: "",
      refund_etc: false,
    };
  },
  created() {
    window.document.title = "마이페이지";
  },
  mounted() {
    if (this.$route.query.idx) {
      this.handleMenu(this.$route.query.idx);
      this.activeName = "second";
    }
    eventBus.$on("closeModal", () => {
      this.closeModal();
    });
    this.getProfile();
    this.getUserCharges();
  },
  computed: {
    ...mapState(["userInfo", "isPaid"]),
  },
  methods: {
    changeRefund() {
      if (this.refund_category == "etc") {
        this.refund_etc = true;
      } else {
        this.refund_etc = false;
      }
    },
    getUserCheckoutDetail(id) {
      let params = {
        id: id,
      };
      fetchUserCheckoutDetail(params).then((res) => {
        this.checkoutData_status = res.data.checkoutData.status;
        this.checkoutData_date = res.data.checkoutData.created_at;
        this.checkoutData_updated_at = res.data.checkoutData.updated_at;
        this.checkoutData_serviceType = res.data.checkoutData.serviceType;
        this.checkoutData_paid_amount = res.data.checkoutData.paid_amount;
        this.checkoutData_total_point = res.data.checkoutData.total_point;
        this.checkoutData_card_name = res.data.checkoutData.card_name;
        this.checkoutData_card_num = res.data.checkoutData.card_num;
        if (res.data.checkoutData.status == "cancelled") {
          this.checkoutData_refund_amount = res.data.checkoutData.refund_amount;
          this.checkoutData_refund_point = res.data.checkoutData.refund_point;
        }
      });
    },
    handleCurrentChange_charge(val) {
      this.currentPage_charge = val;
      this.getUserCharges();
    },
    getUserCharges() {
      let params = {
        page: this.currentPage_charge,
      };
      fetchUserCharges(params).then((res) => {
        this.chargeInfo = res.data.data;
        this.currentPage_charge = res.data.currentPage;
        this.total_charge = res.data.totalPages;
      });
    },
    getUserDeduction() {
      let params = {
        page: this.currentPage_deduction,
      };
      fetchUserdeductions(params).then((res) => {
        this.deductionInfo = res.data.data;
        this.currentPage_deduction = res.data.currentPage;
        this.total_deduction = res.data.totalPages;
      });
    },
    handlePointChargeChange(val) {
      this.currentPage_pointCharge = val;
      this.getPointCharges();
    },
    handlePointDeductionChange(val) {
      this.currentPage_pointDeduction = val;
      this.getPointDeduction();
    },
    getPointCharges() {
      let params = {
        page: this.currentPage_pointCharge,
        mode: "charge",
      };
      fetchPointList(params).then((res) => {
        this.pointChargeInfo = res.data.data;
        this.currentPage_pointCharge = res.data.page;
        this.total_pointCharge = res.data.total;
      });
    },
    getPointDeduction() {
      let params = {
        page: this.currentPage_pointDeduction,
        mode: "deduction",
      };
      fetchPointList(params).then((res) => {
        this.pointDeductionInfo = res.data.data;
        this.currentPage_pointDeduction = res.data.page;
        this.total_pointDeduction = res.data.total;
      });
    },
    requestCertNumber() {
      if (this.mobileNo == "") {
        return alert("핸드폰 번호를 입력해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.veyfyCodeVisible = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          return alert("인증번호가 발송 되었습니다. 핸드폰에서 인증번호를 확인해주세요.");
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyNumber() {
      if (this.certNumber == "") {
        return alert("인증번호를 입력해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.verify = true;
          return alert("정상적으로 인증번호가 확인되었습니다.");
        } else if (res.data.status == 401) {
          this.verify = false;
          return alert(res.data.message);
        }
      });
    },
    handleMenu(index, item_id = "") {
      this.item_id = item_id;
      if (index == "1") {
        this.menuTxt = this.$t("mypage_profile");
        this.getProfile();
      } else if (index == "2") {
        this.getUserCharges();
        this.getUserDeduction();
        this.menuTxt = this.$t("mypage_payment_management");
      } else if (index == "2-2") {
        this.getUserCheckoutDetail(item_id);
        // } else if (index == "2-3") {
        //   window.console.log("index", index);
      } else if (index == "3") {
        this.menuTxt = this.$t("mypage_charge");
        this.getProfile();
        this.chargeAmont = 0;
        this.calculTotal();
      } else if (index == "4") {
        this.getPointCharges();
        this.getPointDeduction();
        this.menuTxt = this.$t("mypage_membership");
      } else if (index == "5") {
        this.getPostList();
        this.menuTxt = this.$t("mypage_qna_management");
      } else if (index == "6") {
        this.menuTxt = this.$t("mypage_changePwd");
      }
      this.menuIdx = index;
    },
    getMembership() {
      let data = this.merchant_uid;
      fetchUserMembership(data).then((res) => {
        if (res.status == 200) {
          this.serviceType = res.data.data.serviceType;
          this.subscribeTerm = res.data.data.paid_at;
          this.nextPayment = res.data.data.schedule_at;
        } else {
          alert("올바르지 않은 접근입니다.");
        }
      });
    },
    handleRefund() {
      if (this.refund_category == "etc") {
        if (this.refund_reason == "" || this.refund_reason == undefined) {
          return alert("기타 환불 사유를 입력해주세요");
        }
      } else if (this.refund_category == "money" || this.refund_category == "usage") {
        this.refund_reason = "";
      } else {
        return alert("환불 사유를 선택해주세요");
      }
      let data = {
        id: this.item_id,
        refund_category: this.refund_category,
        refund_reason: this.refund_reason,
      };
      refundRequest(data).then((res) => {
        if (res.data.status == 200) {
          this.handleMenu("2-2", this.item_id);
        } else {
          alert("환불신청 중 오류가 발생하였습니다. \n 다시 신청 바랍니다.");
        }
      });
    },

    viewComponent(view) {
      this.currentComponent = view;
    },
    closeModal() {
      this.currentComponent = null;
      this.optionDisplayCenterModal = false;
      this.refundDisplayCenterModal = false;
      this.getUserPayments();
      this.$store.dispatch("getAccountInfo");
    },
    /**
     * 함수 이름이 뜻하는 것을 명확하게 해야함.
     */
    closeModal2() {
      this.noteVisible = false;
      this.opinionVisible = false;
      document.querySelector("body").style.overflow = "auto";
    },

    handlePaging(page) {
      if (page > this.totalPages) return false;
      else if (page < 1) return false;
      this.currentPage = page;
      this.getUserPayments();
    },
    handleCount(type, item) {
      let count = item.count;
      if (type == 1) {
        count++;
      } else {
        if (item.count == 1) {
          count = 1;
          return alert("상품개수를 1개 이상으로 설정해주세요.");
        } else if (item.count > 0) {
          count--;
        } else count = 1;
      }
      let basket = this.basket.map((unit) => {
        if (unit._id == item._id) {
          unit.count = count;
          return unit;
        } else {
          return unit;
        }
      });
      let data = {
        basket: basket,
      };
      updateBasket(data).then((res) => {
        if (res.data.status == 200) {
          this.getProfile();
        } else {
          return alert(res.data.message);
        }
      });
    },
    getProfile() {
      fetchUserInfo().then((res) => {
        if (res.status == 200) {
          this.merchant_uid = res.data.data.merchant_uid ? res.data.data.merchant_uid : "";
          this.mobileNo = res.data.data.mobileNo;
          this.postcode = res.data.data.postcode;
          this.address = res.data.data.address;
          this.addressDetail = res.data.data.addressDetail;
          this.username = res.data.data.username;
          this.companyName = res.data.data.companyName;
          this.type = res.data.data.type;
          this.point = res.data.data.point;
          this.havingPoint = res.data.data.point; // 변수 정의 중복 이슈
        } else {
          return alert(res.data.message);
        }
      });
    },

    handleOptionModal(item) {
      this.optionDisplayCenterModal = true;
      this.basketElement = item;
      this.idx = item.productId.id;
      // this.$router.push({ name: "productDetail", query: { id: id } });
    },
    handleRefundModal(item) {
      this.refundDisplayCenterModal = true;
      this.refundItem = item;
      this.viewComponent("RefundRequest");
    },
    handleMove(id, type) {
      if (type == "product") {
        this.$router.push({ name: "productDetail", query: { id: id } });
      } else if (type == "userPaymentDetail") {
        this.$router.push({
          name: "userPaymentDetail",
          query: { imp_uid: id },
        });
      }
    },
    handleDeleteBasket(id) {
      deleteBasket(id).then((res) => {
        if (res.status == 200) {
          if (id == "all") {
            this.basket = [];
          } else {
            this.basket = this.basket.filter((item) => {
              if (item._id != id) return item;
            });
          }
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleCancel() {
      let result = confirm("구독취소 하시겠습니까?");
      if (result) {
        cancelSubscribe().then((res) => {
          if (res.data.status == 200) {
            alert(res.data.message);
          } else {
            alert("취소 처리가 되지 않았습니다. 다시 확인해주세요");
          }
        });
      }
    },
    handleQna(id) {
      this.noteVisible = true;
      document.querySelector("body").style.overflow = "hidden";
      fetchPostDetail(id).then((res) => {
        if (res.data.status == 200) {
          this.qnaData = res.data.data;
        } else {
          return alert(res.data.message);
        }
      });
    },
    getUserPayments() {
      let params = {
        page: this.currentPage,
      };
      fetchUserPayments(params).then((res) => {
        this.buyInfo = res.data.data;
        this.currentPage = res.data.currentPage;
        this.total = res.data.totalPages;
      });
    },
    refundProduct(id) {
      let data = {
        reason: this.reason,
      };
      refundState(id, data).then((res) => {
        if (res.status == 200) {
          this.getUserPayments();
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
    },
    handleDeleteBuyInfo(id) {
      deletePaymentHistory(id).then((res) => {
        if (res.status == 200) {
          if (id == "all") {
            this.buyInfo = [];
          } else {
            this.buyInfo = this.buyInfo.filter((item) => {
              if (item._id != id) return item;
            });
          }
        } else {
          return alert(res.data.message);
        }
      });
    },
    submit() {
      if (this.userInfo.username == "") {
        return alert("이름을 입력해 주세요.");
      } else if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
      }
      if (this.veyfyCodeVisible && this.verify == false) {
        return alert("핸드폰 인증을 진행해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
        companyName: this.companyName,
      };
      updateProfileInfo(data).then((res) => {
        if (res.data.status == 200) {
          this.veyfyCodeVisible = false;
          this.verify = false;
          alert("회원정보가 수정 되었습니다.");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    updatePwd() {
      if (this.password == "") {
        return alert("이름을 입력해 주세요.");
      } else if (this.newPwd == "") {
        return alert("신규 비밀번호를 입력해 주세요.");
      } else if (this.newPwdConfirm == "") {
        return alert("신규 비밀번호를 주세요.");
      } else if (this.newPwdConfirm != this.newPwd) {
        return alert("신규 비밀번호 확인해 주세요.");
      }
      let result = this.verifyPassword(this.newPwd);
      if (result == false) {
        return;
      }
      let data = {
        password: sha256(this.password),
        newPwd: sha256(this.newPwd),
      };
      changePassword(data).then((res) => {
        if (res.data.status == 200) {
          alert("비밀번호가 수정 되었습니다.");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    calculate(e) {
      this.plusPoint = 0;
      this.totalAmount;
      this.chargeAmont =
        e.target.value.split("")[0] == "0"
          ? e.target.value
              .split("")
              .splice(1)
              .join("")
          : e.target.value;
      if (this.chargeAmont > 999999) {
        this.calculPlus();
      }
      this.calculTotal();
    },
    calculPrice(val) {
      if (this.chargeAmont == "") {
        this.chargeAmont = 0;
      }
      this.plusPoint = 0;
      this.chargeAmont = parseInt(this.chargeAmont) + parseInt(val);
      if (this.chargeAmont > 999999) {
        this.calculPlus();
      }
      this.calculTotal();
    },
    amountCancel() {
      this.chargeAmont = 0;
      this.totalPoint = 0;
    },
    calculPlus() {
      if (this.chargeAmont == 1000000) {
        this.plusPoint = Math.ceil(this.chargeAmont / 100 / 10);
      }
      let num = 0;
      for (let i = 1000000; i < parseInt(this.chargeAmont) + 1; i += 500000) {
        num = Math.ceil(i / 100 / 10);
      }
      this.plusPoint = num;
    },
    calculTotal() {
      this.totalPoint = 0;
      this.totalAmount = 0;
      let charge = this.chargeAmont != "" ? parseInt(this.chargeAmont) / 100 : 0;
      let plus = this.plusPoint != "" ? parseInt(this.plusPoint) : 0;
      let having = this.havingPoint != "" ? parseInt(this.havingPoint) : 0;
      // 부가세 10% 포함
      let pay = this.chargeAmont != "" ? Math.ceil((parseInt(this.chargeAmont) / 100) * 10) + parseInt(this.chargeAmont) : 0;
      if (this.chargeAmont < 100 || "") {
        this.totalPoint == 0;
      } else {
        this.totalPoint = Math.ceil(charge + plus + having);
        this.totalAmount = pay;
      }
    },
    handleCharge() {
      if (this.chargeAmont < 9999) {
        alert("만원 이상 결제해주시길 바랍니다.");
        return;
      }
      //가독성, 통일성 이슈
      if (!confirm(`${this.comma(this.totalAmount)}원을 결제 하시겠습니까? `)) {
        return;
      }
      let data = {
        pg: "html5_inicis",
        pay_method: "card",
        merchant_uid: "issue_billingkey_" + new Date().getTime(),
        name: "reviewMind + Plan 1",
        amount: this.totalAmount,
        buyer_email: this.userInfo.email,
        buyer_name: this.userInfo.username,
        buyer_tel: this.userInfo.mobileNo,
        buyer_addr: this.userInfo.address,
        buyer_postcode: this.userInfo.zipcode,
      };

      const IMP = window.IMP;
      IMP.init("imp60557482");
      IMP.request_pay(data, (res) => {
        data.imp_uid = res.imp_uid;
        if (res.status == "paid" || res.status == "ready") {
          createCheckout(data).then((res) => {
            if (res) {
              let params = {
                imp_uid: data.imp_uid,
                point: Math.floor(this.chargeAmont / 100),
                plus_point: this.plusPoint != "" ? parseInt(this.plusPoint) : 0,
              };
              getCheckoutComplete(params).then((res) => {
                if (res.status === 200) {
                  this.getProfile();
                  this.handleMenu("3");
                }
              });
              // .catch((payErr) => {
              //   this.handleMenu("3");
              //   window.console.error("payment Error!", payErr.response.data.message);
              // });
            } else {
              deleteCheckout(res.imp_uid).then((response) => {
                if (response.status == 200) {
                  alert(res.error_msg);
                }
              });
            }
          });
        }
      });
    },
    postReset() {
      this.content = "";
      this.postTitle = "";
      this.category = "";
    },
    postSubmit() {
      if (this.postTitle == "") {
        return alert("제목을 입력해 주세요");
      } else if (this.content == "") {
        return alert("내용을 입력해 주세요");
      } else if (this.content.length < 10) {
        return alert("문의 내용을 최소 10자 이상 입력해주세요.");
      }
      let data = {
        content: this.content,
        title: this.postTitle,
        category: this.category,
      };
      createPost(data).then((res) => {
        if (res.data.status == 200) {
          this.activeName = "first";
          this.postReset();
          this.getPostList();
          return alert("질문 등록이 완료 되었습니다.");
        } else {
          return alert(res.data.message);
        }
      });
    },
    getPostList() {
      let params = {
        page: this.postCurrentPage,
      };
      fetchPostList(params).then((res) => {
        if (res.data.status == 200) {
          this.postList = res.data.postList;
          this.postTotal = res.data.total;
          this.postCurrentPage = res.data.page;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleCurrentChange_post(val) {
      this.postCurrentPage = val;
      this.getPostList();
    },
  },
};
</script>
